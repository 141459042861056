import { useFetchers, useNavigation } from "@remix-run/react";
import NProgress from "nprogress";
import { useEffect, useMemo } from "react";

/**
 * When using the useNavigate hook with location state `omitNProgress` set to true,
 * the NProgress bar will not be shown.
 */
export default function useNprogress() {
	const navigation = useNavigation();
	const fetchers = useFetchers();

	const loadingState = useMemo<"idle" | "loading">(
		function getGlobalState() {
			const states = [
				navigation.location?.state?.omitNProgress === true
					? "idle"
					: navigation.state,
				...fetchers.map((fetcher) => fetcher.state),
			];
			if (states.every((state) => state === "idle")) return "idle";
			return "loading";
		},
		[navigation.location?.state?.omitNProgress, navigation.state, fetchers],
	);

	useEffect(() => {
		NProgress.configure({
			showSpinner: false,
		});
	}, []);

	useEffect(() => {
		if (loadingState === "loading") NProgress.start();
		if (loadingState === "idle") NProgress.done();
	}, [loadingState]);
}
